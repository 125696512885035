import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import {
  detailMedium,
  detailSmall,
  heading100,
  heading100Light,
  heading150,
  heading300,
  heading50,
  paragraphTextMedium,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";

import Fast from "./assets/fast-icon.inline.svg";
import Accurate from "./assets/accurate-icon.inline.svg";
import Proven from "./assets/proven-icon.inline.svg";

const br = <br />;

const title = <>Next generation{br} testing with PIP</>;
const byline = `All of our products utilise PIP, a platform technology developed by our team of materials scientists. PIP testing enables the measurement of stress-strain curves from indentation test data.`;
const buttonText = `A Brief Overview of PIP`;
const desktopButtonDemo = `Book a demo`;
const desktopButtonQuote = `A Brief Overview of PIP`;

const icons = [
  {
    icon: <Fast />,
    title: "Fast",
    description:
      "Measure stress-strain curves and metal strength parameters in minutes, non-destructively.",
  },
  {
    icon: <Accurate />,
    title: "Accurate",
    description:
      "Accurate and reproducible mechanical properties can be measured quickly across a broad range of metallic materials.",
  },
  {
    icon: <Proven />,
    title: "Proven",
    description:
      "Developed by world-leading scientists all formerly of the University of Cambridge, and used by leading institutions and industry heavy-weights, globally.",
  },
];

const IconBlock = ({
  icon,
  title,
  description,
}: {
  icon: JSX.Element;
  title: string;
  description: string;
}) => (
  <IconBlockContainer>
    <IconImageContainer>{icon}</IconImageContainer>
    <IconTextContent>
      <IconTitle>{title}</IconTitle>
      <IconDescription>{description}</IconDescription>
    </IconTextContent>
  </IconBlockContainer>
);

const Details = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "industry_details.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = getImage(data.render);

  return (
    <Wrapper>
      <Container>
        <TextContent>
          <Title>{title}</Title>
          <Byline>{byline}</Byline>
          <Link to="/blogs/a-brief-overview-of-pip">
            <MobileButton>
              <Button size="small" tier="secondary" arrow >
                {buttonText}
              </Button>
            </MobileButton>
          </Link>
          <DesktopButtons>
            <Link to="/blogs/a-brief-overview-of-pip">
              <Button size="medium" tier="bordered" arrow >
                {desktopButtonQuote}
              </Button>
            </Link>
          </DesktopButtons>
        </TextContent>
      </Container>
      <IconsContainer>
        {icons.map((icon) => (
          <IconBlock
            icon={icon.icon}
            title={icon.title}
            description={icon.description}
            key={icon.title}
          />
        ))}
      </IconsContainer>
    </Wrapper>
  );
};

export default Details;

const Wrapper = styled("div", {
  width: "100%",
  maxWidth: 1110,
  "@md": {
    backgroundColor: "$transparent",
    display: "flex",
    width: "90%",
    margin: "auto",
    justifyContent: "space-between",
    marginBottom: 161,
    marginTop: 137,
  },
});
const Container = styled("div", {
  width: "90%",
  margin: "auto",
  "@md": { flexBasis: "48%", marginRight: "5%", width: "100%", margin: 0 },
});

const ImageWrapper = styled("div", {
  "@md": { display: "none" },
});
const TextContent = styled("div", {
  padding: "22px 0",
  maxWidth: 535,
  "@md": {
    padding: 0,
    maxWidth: "none",
    flexBasis: "50%",
  },
});
const Title = styled("h4", {
  ...heading150,
  marginBottom: 16,
  "@md": {
    ...heading300,
    marginTop: 25,
  },
});

const Byline = styled("p", {
  ...detailSmall,
  marginBottom: 0,
  "@md": {
    ...paragraphTextMedium,
    color: "$bayoux",
    marginBottom: 24,
    maxWidth: 563,
  },
});
const IconBlockContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  textAlign: "center",
  maxWidth: 360,
  "&:not(:last-child)": {
    marginBottom: 33,
  },
  "@lg": {
    flexDirection: "row",
    alignItems: "flex-start",
    textAlign: "left",
    width: "100%",
    maxWidth: 414,
  },
});
const IconImageContainer = styled("div", {
  "@lg": {
    width: 80,
    marginRight: 18,
  },
});
const IconTitle = styled("h4", {
  ...heading50,
  margin: 0,
  marginTop: 12,
  "@md": {
    ...heading100Light,
    marginTop: 0,
  },
});
const IconDescription = styled("p", {
  ...detailSmall,
  color: "$black55",
  marginTop: 4,
  marginBottom: 0,
  "@md": {
    ...detailMedium,
    color: "$black55",
  },
});
const IconsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: 10,
  paddingBottom: 30,
  "@md": {
    padding: 0,
  },
});
const IconTextContent = styled("div", {});
const MobileButton = styled("div", {
  "@md": { display: "none" },
  button: { paddingLeft: 0 }
});
const DesktopButtons = styled("div", {
  display: "none",
  "@md": { display: "flex" },
});
