import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Details from "../components/index/Details";
import Explore from "../components/index/Explore";
import IndexHero from "../components/index/IndexHero";
import ThePlastometer from "../components/index/ThePlastometer";
import Layout from "../components/Layout";
import Newsletter from "../components/shared/Newsletter/Newsletter";
import Testimonial from "../components/shared/Testominial/Testimonial";
import { styled } from "../stitches/stitches.config";
import ProductDisplay from "../components/shared/ProductsDisplay/ProductsDisplay";
import KnowledgeCentrePreview from "../components/knowledgeCentre/KnowledgeCentrePreview";
import NextStep from "../components/shared/NextSteps/NextSteps";

const seo = {
  description:
    "Profilometry-based Indentation Plastometry (PIP) is a revolutionary new approach to the mechanical testing of metals.",
};

const preview = {
  title: "Case Studies",
  byline:
    "At Plastometrex we are always exploring a wide range of applications for the PIP method. See below for our latest case studies.",
  linkPath: "/case-studies",
};

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "testimonials/roger_reed.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 134, placeholder: BLURRED)
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/caseStudies/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
          }
        }
      }
    }
  `);

  const image = getImage(data.render);

  return (
    <Layout seo={seo} backgroundColorUnderneath="white" backgroundColor="white">

      <IndexHero />
      <Container>
        <Details />
        <StyledTestimonial>
          <Testimonial
            image={image}
            name={"Prof. Roger Reed FREng FIMMM"}
            position={
              "Professor of Materials and Solid Mechanics  |  Oxford University"
            }
            description={
              "“The PLX-Benchtop enables us to generate critical material property data far more efficiently than conventional methods. I have been thoroughly impressed by the machine, it is both easy to use and reliable”"
            }
          />
        </StyledTestimonial>
        <ProductDisplay />
        <KnowledgeCentrePreview headingDetails={preview} data={data} />
        <ExploreWrapper>
          <Explore />
        </ExploreWrapper>
        <Grid>
          <NewsletterWrapper>
            <Newsletter />
          </NewsletterWrapper>
          <NextStepWrapper>
            <NextStep />
          </NextStepWrapper>
        </Grid>
      </Container>
    </Layout>
  );
};

export default IndexPage;

const Container = styled("div", {
  margin: "auto",
  maxWidth: 1400,
  "@lg": {
    width: "95%",
    paddingBottom: 60,
    marginTop: 50,
  },
});

const NewsletterWrapper = styled("div", {
  order: 3,
  "@md": {
    paddingBottom: 0,
    order: 2
  }
});

const NextStepWrapper = styled("div", {
  order: 2,
  "@md": {
    paddingBottom: 10,
    order: 3
  }
});

const ExploreWrapper = styled("div", {
  background: "linear-gradient(180deg, #F5FAFF 25%, rgba(245, 250, 255, 0) 100%)",
  "@md": {
    paddingTop: "64px",
    marginBottom: "64px",
  }
}

);
const Grid = styled("div", {
  display: "grid",
});

const StyledTestimonial = styled("div", {
  margin: "48px auto 0 auto",
  width: "100%",
  maxWidth: 1240,
  "@md": {
    marginTop: "0",
  }
});