import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import {
  detailSmall,
  heading100,
  heading150,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import TestimonialOvalTop from "./testimonialOvalTop.png";
import TestimonialOvalBottom from "./testimonialOvalBottom.png";
import TestimonialOvalLeft from "./testimonialOvalLeft.png";

interface Props {
  image?: IGatsbyImageData | null;
  description: string;
  name: string;
  position: string;
  imageAlt?: string;
}

const Testimonial = ({
  image,
  description,
  name,
  position,
  imageAlt,
}: Props) => {
  return (
    <Wrapper>
      <Container>
        <ImageWrapper>
          {image && (
            <GatsbyImage image={image} alt={imageAlt ? imageAlt : ""} />
          )}
        </ImageWrapper>
        <Name>{name}</Name>
        <Position>{position}</Position>
        <Description large={!image}>{description}</Description>
      </Container>
    </Wrapper>
  );
};

export default Testimonial;

const Wrapper = styled("div", {
  backgroundColor: "$blue100",
  borderRadius:"10px",
  width:"90%",
  margin:"0 auto",
  backgroundImage: `url(${TestimonialOvalTop}),url(${TestimonialOvalBottom})`,
  backgroundPosition: "left top, right bottom",
  backgroundRepeat: "no-repeat,no-repeat",
  backgroundSize: "100%,100%",
  "@md":{    
    backgroundImage: `url(${TestimonialOvalLeft})`,
    backgroundPosition: "left top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
  }
});
const Container = styled("div", {
  width: "90%",
  maxWidth: 898,
  margin: "auto",
  padding: "32px 0",
  textAlign: "left",
  display: "grid",
  "@md":{
    textAlign: "center",
    padding: "64px 0",
  }
});
const ImageWrapper = styled("div", {
  marginTop: -69,
  textAlign: "center",
  order:1,
    "@md": {
      marginTop: 0,
      order:1,
    },
  "& img": {
    width: 134,
    height: 134,
    borderRadius: "50%",
    overflow: "hidden",    
  },
});
const Description = styled("p", {
  ...heading150,
  order:2,
  color: "$white",
  marginTop: 21,
  marginBottom: 21,
  variants: {
    large: {
      true: {
        ...heading150,
        color: "$white",
      },
    },
  },
  "@md": {order:4,}
});
const Name = styled("p", {
  ...heading100,
  color: "$white",
  marginBottom: 0,
  order:3,
  "@md":{
    order:2,
  }
});
const Position = styled("p", {
  ...detailSmall,
  color: "$white",
  marginTop: 0,
  order:3,
  "@md":{
    order:2,
  }
});
