import { Link } from "gatsby";
import React from "react";
import {
  heading100,
  heading150,
  heading200,
  heading300,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";
import AcademiaIcon from "./assets/academia.inline.svg";
import IndustryLogo from "./assets/industry.inline.svg";

const title = `Explore your usecase`;
const byline = `See how our technology can enhance your research, support your manufacturing processes, and save time and money.`;

const blocks = [
  {
    icon: <AcademiaIcon />,
    title: "Academia",
    description:
      "We understand the importance of experimental test equipment and the role it can play in ground-breaking research. We're here to help.",
    linkPath: "/academia",
  },
  {
    icon: <IndustryLogo />,
    title: "Industry",
    description:
      "We understand the importance of accurate material property data and the role it plays in designing and assessing products. We're here to help.",
    linkPath: "/industry",
  },
];

const Block = ({
  icon,
  title,
  description,
  linkPath,
}: {
  icon: any;
  title: string;
  description: string;
  linkPath: string;
}) => (
  <BlockWrapper>
    {icon}
    <BlockTitle>{title}</BlockTitle>
    <BlockDescription>{description}</BlockDescription>
    <Link to={linkPath}>
      <Button size="large" tier="primary" arrow>
        Learn More
      </Button>
    </Link>
  </BlockWrapper>
);

const Explore = () => {
  return (
    <Container>
      <BlockContainer>
        {blocks.map((block) => (
          <Block
            icon={block.icon}
            title={block.title}
            description={block.description}
            linkPath={block.linkPath}
            key={block.linkPath}
          />
        ))}
      </BlockContainer>
      <TextContent>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </TextContent>
    </Container>
  );
};

export default Explore;

const Container = styled("div", {
  width: "90%",
  maxWidth: 1144,
  margin: "auto",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  display: "flex",
  flexDirection: "column-reverse",
  "@md": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "20px 0 10px 0",
  },
});

const BlockContainer = styled("div", {
  "@md": {
    display: "flex",
    flexBasis: "67%"
  },
});

const TextContent = styled("div", {
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  "@md": {
    flexBasis: "25%",
  },
});
const Title = styled("h4", {
  ...heading150,
  marginBottom: 18,
    "@md": {
    ...heading300,
    marginTop: 25,
  },
});
const Byline = styled("p", {
  ...paragraphTextMedium,
});
const BlockWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid rgba(26, 26, 26, 0.1)",
  borderRadius: 15,
  padding: "45px 5%",
  marginBottom: 16,
  textAlign: "center",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "$white",
  "@md": {
    flexBasis: "47%",
    backgroundColor: "$white",
    "&:hover": {
      backgroundColor: "$white",
      border: "1px solid $blue100",
    },
  },
});
const BlockTitle = styled("h6", {
  ...heading100,
  margin: "26px 0 12px 0",
  "@md": {
    ...heading200
  }
});
const BlockDescription = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
  marginBottom: 26,
});

